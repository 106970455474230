//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-408:_6068,_6792,_200,_8744,_5896,_76,_8832,_7664;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-408')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-408', "_6068,_6792,_200,_8744,_5896,_76,_8832,_7664");
        }
      }catch (ex) {
        console.error(ex);
      }