const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = globalThis.Convermax.config?.wheelsFields || [
  'wheel_diameter',
  'wheel_width',
  'wheel_bolt_pattern',
];
const tiresFields = globalThis.Convermax.config?.tiresFields || ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal', 'Vehicle'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');
  if (!compareBtn) {
    return;
  }

  compareCount.textContent = productsIdsToCompare.length;
  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

globalThis.Convermax.handleWishlistButton = () => (e) => {
  const wishListDropdown = e.currentTarget;
  const wishListDropdownMenu = wishListDropdown.nextSibling;

  wishListDropdownMenu.classList.toggle('is-open');
  window.document.body.addEventListener('click', (e) => {
    if (e.target !== wishListDropdown && e.target.parentNode !== wishListDropdown) {
      wishListDropdownMenu.classList.remove('is-open');
    }
  });
};

const getFitmentSearchTitle = () =>
  window.location.pathname === categorySelectionPageUrl
    ? 'Categories'
    : window.location.pathname === brandSelectionPageUrl
      ? 'Brands'
      : 'Parts';

const updateCallback = () => {
  if (window.affirm) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

function InitFunc() {
  const { document, location } = window;
  if ([categorySelectionPageUrl, brandSelectionPageUrl].includes(location.pathname)) {
    document.body.classList.add('cm_category-selection');
  }

  if (
    document.body.classList.contains('page-type-product') &&
    !document.querySelector('#form-action-addToCart')
  ) {
    const wishlistFormElem = document.querySelector('.form-wishlist');
    if (wishlistFormElem) {
      wishlistFormElem.style.position = 'static';
    }
  }
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  defaultView: '3',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ? 'parts' : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: window.Convermax.config?.categorySelectionPageRedirect ?? true,
    getFitmentSearchTitle,
    expectResponseRedirect: true,
    onVehicleSelected: () =>
      window.document.querySelector('body.has-activeNavPages #menu-mobile .themevale_close')?.click(),
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { selector: '#cm_results' },
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['page-type-category', 'page-type-brand']
        .map((className) => `body.${className} #product-listing-container`)
        .join(),
      template: 'MainContent',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: {
        firstChildOf: ['page-type-category', 'page-type-brand']
          .map((className) => `body.${className} #faceted-search-container`)
          .join(),
        class: 'cm_facet-panel_container',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 600,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: 'body.page-type-default .main',
        class: 'container cm_vehicle-widget__home_container',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 600,
      visibleIf: () =>
        !window.document.querySelector('body.page-type-default #cm_home-ymm') &&
        window.document.querySelector('.body > .themevale_heroCarousel, .body > .heroCarousel'),
    },
    {
      name: 'HomeVehicleWidget_layout-2',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.page-type-default .main .themevale_section_special .item-left .heroCarousel',
        class: 'cm_vehicle-widget__home_container cm_vehicle-widget__home_container-layout-2',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 600,
      visibleIf: () =>
        !window.document.querySelector(
          'body.page-type-default #cm_home-ymm, .body > .themevale_heroCarousel, .body > .heroCarousel',
        ),
    },
    {
      name: 'HomeVehicleWidget_custom',
      type: 'VehicleWidget',
      location: {
        selector: 'body.page-type-default #cm_home-ymm',
        class: 'cm_vehicle-widget__home_container',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 600,
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.item--cart',
        class: 'cm_garage_container items',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        firstChildOf: '#menu-mobile .navPages-list--user',
        wrapper: 'li',
        class: 'navPages-item',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: 'body.page-type-product .productView-options' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTabDesktop',
      type: 'FitmentTable',
      location: '.themevale_tabs a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTabDesktop',
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: '#tab-fitment a[href="#tabFitment-content"]',
      template: 'fitmentSearch/fitmentTableTabMobile',
    },
    {
      name: 'FitmentTable',
      location: '#tabFitment-content',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        selector: 'body.page-type-category .page',
        class: 'container',
      },
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'body.page-type-brands .page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'columns', // FIXME: 'grid' view not yet styled for the Spark theme
    },
    {
      name: 'SearchBox',
      location: '.themevale_search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.item--searchMobile',
        class: 'items item--searchMobile',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
